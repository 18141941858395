import cookie from 'react-cookies';
import CryptoJS from 'crypto-js';
/**
 * Determines whether the current build env is dev. 
 * 
 * @returns {Boolean} Returns the value of NODE_ENV|| REACT_NODE_ENV
 */



function encryptData(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), "A1b2C3d4E5f6G7h8I9j0K!l@M#n$O%p^Q&r*S(t)U_v+w=x-Y{z}").toString();
}

function encodeKey(key) {
    return btoa(key);
}


export function setSecureCookie(key, value) {
    const keyName = encodeKey(key);
    const encryptedValue = encryptData(value);
    
    cookie.save(keyName, encryptedValue, {
        secure: true, 
        sameSite: 'Strict', 
        path: '/', 
    });
}

function decryptData(encryptedData) {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, "A1b2C3d4E5f6G7h8I9j0K!l@M#n$O%p^Q&r*S(t)U_v+w=x-Y{z}");
        const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedText);
    } catch (error) {
        console.error('Decryption failed:', error);
        return null;
    }
}


export function getDecryptedCookie(key) {
    const encryptedKey = encodeKey(key); 
    const encryptedValue = cookie.load(encryptedKey); 
    
    if (encryptedValue) {
        return decryptData(encryptedValue); 
    }
    
    return null; 
}

const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export default isDev